import React from 'react';

import { getImageSrc } from '../utils';

const CollectionItem = ({ item, onAddProduct, loading, loaded, className, style = { position: 'relative' } }) => {
  const indexed_files = item.files.reduce((o, f) => ({ ...o, [f.file_id]: f }), {});
  const default_image = item.item_images.map(ii => indexed_files[ii.file_id])[0];
  const url = loading ? '/images/gears.gif' : (loaded ? null : getImageSrc(default_image, 'medium'));
  const image_style = {
    backgroundImage: `url('${url}')`,
    backgroundColor: loaded ? '#488ea1' : null,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: loading ? 'center' : 'top center',
    backgroundSize: loading ? '60px 60px' : 'cover',
    marginTop: '15px',
    marginBottom: '15px'
  };
  const item_prices = (item.options || []).filter(o => 0 == o.hidden && 0 != o.unit_price).map(o => parseFloat(o.unit_price));
  const minimum_price = Math.min.apply(null, item_prices);
  const maximum_price = Math.max.apply(null, item_prices);

  const displayPrice = item_prices.length ? (minimum_price === maximum_price ? `${item.currency_symbol}${minimum_price.toFixed(2)}` : `${item.currency_symbol}${minimum_price.toFixed(2)} - ${item.currency_symbol}${maximum_price.toFixed(2)}`) : null;

  return (
    <div className={'large-2 medium-3 small-6 columns ' + className} style={style}>
      <div className="search-result" style={image_style} onClick={e => { e.stopPropagation(); if (!loading) { onAddProduct(item); } }}>
        <div className="product-info">
          <a className="vendor-page" href={`/vendor.php?id=${item.supplier_id}&division_id=${item.division_id}`} target="_blank">{item.division_name}</a>
          <h4>{item.item_name}</h4>
          {displayPrice && <span className="price">{displayPrice}</span>}
          <div className="sku">#{item.item_sku}<br /></div>
        </div>
      </div>
    </div>
  );
};

export default CollectionItem;
